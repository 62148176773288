var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-row',{attrs:{"gutter":24}},_vm._l((_vm.stats),function(stat,index){return _c('a-col',{key:index,staticClass:"mb-24",attrs:{"span":24,"lg":12,"xl":6}},[_c('WidgetCounter',{attrs:{"title":stat.title,"value":stat.value,"prefix":stat.prefix,"suffix":stat.suffix,"icon":stat.icon,"status":stat.status}})],1)}),1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{staticClass:"mb-24 text-right",attrs:{"span":12,"lg":12,"xl":24}},[_c('a-button',{staticClass:"mx-2",on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("Retour")])],1),_c('a-col',{staticClass:"mb-24",attrs:{"span":24,"lg":24,"xl":24}},[[_c('h6',{staticClass:"font-semibold m-0"},[_vm._v(" Affecter client a un autre collecteur ")])],_c('a-form',{staticClass:"login-form mt-4",attrs:{"layout":"inline","id":"components-form-demo-normal-login","form":_vm.form,"hideRequiredMark":true},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"Selectionnez le collecteur"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'collecteur',
            {
              rules: [
                {
                  required: true,
                  message: 'Collecteur incorrect!',
                } ],
            } ]),expression:"[\n            'collecteur',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Collecteur incorrect!',\n                },\n              ],\n            },\n          ]"}],staticStyle:{"width":"120px"}},_vm._l((_vm.collecteurs),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.nom)+" "+_vm._s(item.prenom)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"Code secret","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'code_secret',
            {
              rules: [
                {
                  required: true,
                  message: 'Code secret incorrect!',
                } ],
            } ]),expression:"[\n            'code_secret',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Code secret incorrect!',\n                },\n              ],\n            },\n          ]"}],attrs:{"type":"text","placeholder":"Code secret"}})],1),_c('a-form-item',{attrs:{"colon":false}},[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" Affecter ")])],1)],1)],2),_c('a-col',{staticClass:"mb-24",attrs:{"span":24,"lg":24,"xl":24}},[_c('a-card',{staticClass:"card card-body border-0"},[_c('a-table',{attrs:{"columns":_vm.columns,"data-source":_vm.data,"row-selection":_vm.rowSelection,"expanded-row-keys":_vm.expandedRowKeys},on:{"update:expandedRowKeys":function($event){_vm.expandedRowKeys=$event},"update:expanded-row-keys":function($event){_vm.expandedRowKeys=$event}},scopedSlots:_vm._u([{key:"operation",fn:function(text, record){return [_c('router-link',{attrs:{"to":{ name: 'Client_detail', params: { id: record.key } }}},[_c('a-button',{attrs:{"type":"primary","size":"small"}},[_vm._v("Détail")])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }